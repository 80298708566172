import { Props as HeaderProps } from 'app/components/Common/Header'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { HeaderDataQuery } from 'graphql-types'
import { compact, uniqBy } from 'lodash'

export const getHeaderProps = (
  data: HeaderDataQuery,
  languageCode: string,
  languagePrefix: string | null,
  menuItems: { [key: string]: MenuItemProps[] }[],
  pageID?: string,
): HeaderProps | undefined => {
  const contacts = data.cms.company
  const address = contacts?.address || undefined
  const country = contacts?.country || undefined
  const locality = contacts?.locality || undefined
  const postalCode = contacts?.postal_code || undefined
  const email = contacts?.email || undefined
  const fax = contacts?.fax || undefined
  const IBEID = contacts?.ibeid || undefined
  const region = contacts?.region || undefined
  const whatsapp = contacts?.whatsapp || undefined

  const languagesList = data.allSitePage.nodes
    .filter((t) => t.pageContext?.id === pageID)
    .map((node) => ({
      pageID: node.pageContext?.id || undefined,
      label: node.pageContext?.languagePrefix || 'en',
      languagePrefix: node.pageContext?.languagePrefix || null,
      URL: node.path,
    }))

  const phone = data.cms.company?.phone || undefined
  const siteName = data.site?.siteMetadata?.title || undefined
  const backgroundImage = compact(data.cms.home?.hero_images)
    .sort((a: any, b: any) => a.sort - b.sort)
    .map(({ directus_files_id }) => {
      const imageObj = directus_files_id?.file?.childImageSharp?.gatsbyImageData
      return imageObj
        ? {
            src: imageObj.images.fallback.src || undefined,
            srcSet: imageObj.images.fallback.srcSet || undefined,
            width: imageObj.width || undefined,
            height: imageObj.height || undefined,
          }
        : undefined
    })[0]

  const benefits = data.cms.reservation_benefits?.translations?.filter(
    (t) => t?.languages_code?.code === languageCode,
  )[0]

  const benefitsDescription = benefits?.description || undefined
  const benefitsLabel = benefits?.label || undefined
  const benefitsChecklist = compact(
    data.cms.reservation_benefits?.checklist,
  ).map(({ icons_id }) => {
    const traslation = icons_id?.translations?.filter(
      (t) => t?.languages_code?.code === languageCode,
    )[0]

    const icon = icons_id?.icon?.file?.publicURL || undefined
    const label = traslation?.label || undefined

    return {
      icon,
      label,
    }
  })

  const logo = compact(data.logo.nodes)[0].publicURL || undefined
  const logoSticky = compact(data.logoSticky.nodes)[0].publicURL || undefined

  const navigationItems = compact(
    compact(menuItems.map((lang) => lang[languageCode as 'en-US']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return {
    address,
    backgroundImage,
    country,
    locality,
    postalCode,
    benefitsDescription,
    benefitsLabel,
    benefitsChecklist,
    email,
    fax,
    IBEID,
    languageCode,
    languagePrefix,
    languagesList: uniqBy(languagesList, 'label'),
    logo,
    logoSticky,
    menuItems: uniqBy(navigationItems, 'title') || undefined,
    phone,
    region,
    siteName,
    whatsapp,
  }
}
