import { Header as HeaderComponent } from 'app/components/Common/Header'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { Variant } from 'app/components/Hero'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getHeaderProps } from './getHeaderProps'

export interface Props {
  pageID?: string
  languageCode?: string
  languagePrefix?: string | null
  menuItems: { [key: string]: MenuItemProps[] }[]
  variant?: Variant
}

export const Header = memo(function Header({
  pageID,
  languageCode,
  languagePrefix,
  menuItems,
  variant = 'default',
}: Props) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderData {
          site {
            siteMetadata {
              title
            }
          }
          allSitePage {
            nodes {
              pageContext
              path
            }
          }
          logo: allFile(filter: { name: { eq: "logo-negative" } }) {
            nodes {
              publicURL
            }
          }
          logoSticky: allFile(filter: { name: { eq: "logo-sticky" } }) {
            nodes {
              publicURL
            }
          }
          cms {
            languages {
              prefix
            }
            home {
              hero_images {
                sort
                directus_files_id {
                  id
                  file {
                    childImageSharp {
                      gatsbyImageData(layout: FIXED, width: 800)
                    }
                  }
                }
              }
            }
            company {
              email
              ibeid
              address
              country
              fax
              locality
              phone
              postal_code
              region
              whatsapp
            }
            reservation_benefits {
              translations {
                languages_code {
                  code
                }
                description
                label
              }
              checklist {
                icons_id {
                  icon {
                    id
                    filename_download
                    file {
                      publicURL
                    }
                  }
                  translations {
                    languages_code {
                      code
                    }
                    label
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const props = getHeaderProps(
          data,
          languageCode || 'en-US',
          languagePrefix || null,
          menuItems,
          pageID,
        )

        return props ? (
          <HeaderComponent pageID={pageID} {...props} variant={variant} />
        ) : null
      }}
    />
  )
})
