import { Props as FooterProps } from 'app/components/Common/Footer'
import { FooterDataQuery } from 'graphql-types'
import { compact } from 'lodash'

export const getFooterProps = (
  data: FooterDataQuery,
  languageCode: string,
): FooterProps | undefined => {
  const company = data.cms.company
  const address =
    `${company?.address} - ${company?.postal_code} ${company?.locality} ${company?.region} ${company?.country}` ||
    undefined
  const bookingOffice = company?.booking_office || undefined
  const email = company?.email || undefined
  const fax = company?.fax || undefined
  const facebookURL = company?.facebook || undefined
  const IBEID = company?.ibeid || undefined
  const instagramURL = company?.instagram || undefined
  const linkFooterLabel = company?.link_footer_label || undefined
  const linkFooterURL = company?.link_footer_url || undefined
  const logo = compact(data.logo.nodes)[0].publicURL || undefined
  const phone = company?.phone || undefined
  const siteName = data.site?.siteMetadata?.title || undefined
  const vat = company?.vat || undefined
  const whatsapp = company?.whatsapp || undefined

  return {
    address,
    bookingOffice,
    email,
    facebookURL,
    fax,
    IBEID,
    instagramURL,
    languageCode,
    linkFooterLabel,
    linkFooterURL,
    logo,
    phone,
    siteName,
    vat,
    whatsapp,
  }
}
